import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {APP_BASE_HREF} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';


import {AppComponent} from './app.component';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {NotifierModule} from 'angular-notifier';
import { NgxSpinnerModule } from 'ngx-spinner';

import Amplify from 'aws-amplify';
import aws_exports from 'src/aws-exports';

Amplify.configure(aws_exports);


import {AppRoutes} from './app.routing';


@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot(AppRoutes, {
            useHash: true,
            // relativeLinkResolution: 'legacy',
            scrollPositionRestoration: 'enabled'
        }),
        SweetAlert2Module.forRoot(),
        NotifierModule,
        NgxSpinnerModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
