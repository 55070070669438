import {Routes} from '@angular/router';

import {AuthGuardService} from './shared/services/auth-guard.service';
import {AppComponent} from './app.component';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'dashboard',
        canActivate: [AuthGuardService],
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
    }
];
