import {Injectable} from '@angular/core';
import {onAuthUIStateChange, CognitoUserInterface, AuthState} from '@aws-amplify/ui-components';
import {Router} from '@angular/router';
import {Auth, API} from 'aws-amplify';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    cognitoUser: CognitoUserInterface | undefined;
    identityID: string;
    authState: AuthState;
    userGroups = 'user';

    constructor(private router: Router) {
        onAuthUIStateChange((authState, authData) => {
            this.authState = authState;
            this.cognitoUser = authData as CognitoUserInterface;
            if (this.cognitoUser?.signInUserSession.accessToken.payload['cognito:groups'].indexOf('admin') >= 0) {
                this.userGroups = 'admin';
            }
            if (authState === 'signedin') {
                this.router.navigate(['dashboard']);
            } else {
                this.router.navigate(['auth']);
            }
        });
        Auth.currentUserCredentials().then(res => {
            this.identityID = res.identityId;
        });
    }

    isAuthenticated() {
        return this.authState === 'signedin';
    }

    isAdmin() {
        return this.userGroups === 'admin';
    }

    signOut() {
        Auth.signOut().then(() => {
            this.router.navigate(['auth']);
        });
    }

    async signUpUser(email): Promise<any> {
        const apiName = 'AdminQueries';
        const path = '/createUser';
        const myInit = {
            body: {
                email,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        };
        return await API.post(apiName, path, myInit);
    }

    async addUserToGroup(username, groupname): Promise<any> {
        const apiName = 'AdminQueries';
        const path = '/addUserToGroup';
        const myInit = {
            body: {
                username,
                groupname
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        };
        return await API.post(apiName, path, myInit);
    }

    async unRegisterUser(id): Promise<any> {
        const apiName = 'AdminQueries';
        const path = '/deleteUser';
        const myInit = {
            body: {
                id,
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        };
        return await API.post(apiName, path, myInit);
    }


}
